import React from "react";

import "./style.css";

import pound from "../../images/pound.png";

function AboutUs() {
	return (
		<>
			<div className='container banner'>
				<div className='row'>
					<div className='about h1-responsive  my-4 '>
						<p>About Us</p>
					</div>
				</div>
			</div>
			<div className='container abtus '>
				<h1 className='about-content'>What is Vapor Solutions Labs?</h1>
				<p className='content'>
					Vapor Solutions Labs is a Central Virginia E-Liquid manufacturing
					company. Our goal is to create premium quality E-Liquids for our
					customers. Our E-Liquids showcase original flavors that challenge the
					mundane and create a new vaping experience.<br></br>
					<br></br> Vapor Solutions Labs is determined to bring our customers
					the best quality product. We hold ourselves to a high standard and
					expect nothing less than the best. If it doesn’t meet our standard, it
					doesn’t get our label. We create our E-Liquids with the customer’s
					safety as our first priority and the originality of the vapors as our
					second.<br></br>
					<br></br>
					Check out our products page for exciting new releases.
				</p>
				<h1 className='about-content'>What We Do.</h1>
				<p className='content'>
					We are a one-stop-shop. Need a custom E-Liquid? We can mix it. Need a
					logo for your brand? We can design it. Need a website to show off your
					new flavors? You guessed it.
				</p>
				<h1 className='about-content'>What We Offer.</h1>
				<p className='content'>
					Manufacturing, branding, marketing, shipping, and sales, we do it all!
					Check out our Contact Us page. It will direct you to your department
					of interest.
					<ul className='list-unstyled mb-0 reach-out'>
						<li>
							<i className='fas fa-map-marker-alt fa-2x'></i>
							<p>329 Business Lane #100, Ashland, VA 23005</p>
						</li>

						<li>
							<i className='fas fa-phone mt-4 fa-2x'></i>
							<p>888.223.0777</p>
						</li>

						<li>
							<i className='fas fa-envelope mt-4 fa-2x'></i>
							<p className='email-links'>
								<a href='mailto: sales@buffalodistro.com'>
									sales@buffalodistro.com
								</a>
							</p>
							<p>
								<a href='mailto: accounting@buffalodistro.com'>
									accounting@buffalodistro.com
								</a>
							</p>
							<p>
								<a href='mailto: management@buffalodistro.com '>
									management@buffalodistro.com
								</a>
							</p>
						</li>
					</ul>
				</p>

				{/* <div className='col-lg-6 image-padder'>
						<img src={pound} />
					</div> */}
			</div>
		</>
	);
}

export default AboutUs;
