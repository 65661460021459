import React from "react";

import "./style.css";

import grind from "../../images/grind.png";
import offwhite from "../../images/off-white.png";
import nocap from "../../images/no-cap.png";
import chino from "../../images/chino.png";
import karma from "../../images/karma.png";

function theGrind() {
	return (
		<>
			<header className='container '>
				<div className='row green brands'>
					<div className='col-xs-6 logos '>
						<img
							src={grind}
							alt='by-the-pound'
							className='img-fluid '
							height='150px'
							width='500px'
						/>
						<p className='grind-content-header'>
							Available in No Cap, Karma, Chino, and Off White. The Grind
							flavors have a base of crunchy espresso and are built upon with
							the likes of cinnamon, sugar cookie, or vanilla to re-create some
							of your favorite coffee house staples. Rise and Shine!
						</p>
					</div>
				</div>
				<div className='col-lg-12 logos'>
					<div class='thumbnail'>
						<img src={offwhite} alt='offwhite' className='img-fluid' />
						<div class='grind-content'>
							{" "}
							<div className='btngroup'>
								<a
									type='button'
									href='https://buffalodistro.com/product-category/e-juice/the-grind/'
									className='tgwholesale   '>
									Wholesale
								</a>
								<a
									type='button'
									href='https://truevapeusa.com/product-category/ejuice/the-grind/'
									className=' tgretail  '>
									Retail
								</a>
							</div>
						</div>
					</div>

					<div class='thumbnail'>
						<img src={nocap} alt='nocap' className='img-fluid' />
						<div class='grind-content'>
							{" "}
							<div className='btngroup'>
								<a
									type='button'
									href='https://buffalodistro.com/product-category/e-juice/the-grind/'
									className='tgwholesale   '>
									Wholesale
								</a>
								<a
									type='button'
									href='https://truevapeusa.com/product-category/ejuice/the-grind/'
									className=' tgretail  '>
									Retail
								</a>
							</div>
						</div>
					</div>

					<div class='thumbnail'>
						<img src={chino} alt='chino' className='img-fluid' />
						<div class='grind-content'>
							{" "}
							<div className='btngroup'>
								<a
									type='button'
									href='https://buffalodistro.com/product-category/e-juice/the-grind/'
									className='tgwholesale   '>
									Wholesale
								</a>
								<a
									type='button'
									href='https://truevapeusa.com/product-category/ejuice/the-grind/'
									className=' tgretail  '>
									Retail
								</a>
							</div>
						</div>
					</div>

					<div class='thumbnail'>
						<img src={karma} alt='karma' className='img-fluid' />
						<div class='grind-content '>
							{" "}
							<div className='btngroup'>
								<a
									type='button'
									href='https://buffalodistro.com/product-category/e-juice/the-grind/'
									className='tgwholesale   '>
									Wholesale
								</a>
								<a
									type='button'
									href='https://truevapeusa.com/product-category/ejuice/the-grind/'
									className=' tgretail  '>
									Retail
								</a>
							</div>
						</div>
					</div>
				</div>
			</header>
		</>
	);
}

export default theGrind;
