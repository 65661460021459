import React from "react";

import "./style.css";

import one from "../../images/page1.jpg";
import two from "../../images/page2.jpg";
import three from "../../images/page3.jpg";
import four from "../../images/page4.jpg";
import letter from "../../images/letter.jpg";

function Legal() {
	return (
		<>
			<header className='container-fluid yellow'>
				<div className='row green brands'>
					<div className='logos'>
						<img
							src={letter}
							alt='sqeez'
							className='img-fluid  '
							height='200px'
							width='800px'
						/>
					</div>

					<div className='col-xs-6 logos '>
						<img
							src={one}
							alt='Community letter'
							className='img-fluid '
							height='500px'
							width='800px'
						/>
						<img
							src={two}
							alt='Community letter'
							className='img-fluid '
							height='500px'
							width='800px'
						/>
						<img
							src={three}
							alt='Community letter'
							className='img-fluid '
							height='500px'
							width='800px'
						/>
						<img
							src={four}
							alt='Community letter'
							className='img-fluid '
							height='500px'
							width='800px'
						/>
					</div>
				</div>
			</header>
		</>
	);
}

export default Legal;
