import React from "react";

import "./style.css";

import etho from "../../images/etho.png";
import guard from "../../images/guard.png";

function liquidGuard() {
	return (
		<>
			<header className='container yellow'>
				<div className='row green brands'>
					<div className='col-xs-6 logos '>
						<img
							src={guard}
							alt='by-the-pound'
							className='img-fluid '
							height='150px'
							width='500px'
						/>
						<img
							src={etho}
							alt='by-the-pound'
							className='img-fluid '
							height='150px'
							width='500px'
						/>
						<p class='byp-content-header green'>
							With germs being all around us. We decided to step away from
							E-liquid and create a line of hand sanitzers to help people stay
							healthy and clean.
						</p>
						<div className='btngroup'>
							<a
								type='button'
								href='https://buffalodistro.com/product/liquid-guard-hand-sanitizer/'
								className='lgwholesale   '>
								Wholesale
							</a>
							<a
								type='button'
								href='https://truevapeusa.com/product-category/handsanitizer/'
								className=' lgretail  '>
								Retail
							</a>
						</div>
					</div>
				</div>
			</header>
		</>
	);
}

export default liquidGuard;
