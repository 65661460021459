import React from "react";

import "./style.css";
import fourth from "../../images/4th.png";

function TheFourth() {
	return (
		<>
			<header className='container yellow'>
				<div className='row green brands'>
					<div className='col-xs-6 logos '>
						<img
							src={fourth}
							alt='by-the-pound'
							className='img-fluid '
							height='150px'
							width='500px'
						/>
						<p class='fourth-content-header'>
							Now you can get that same red, white, and blue rocket they handed
							to you back in the day directly in your pod system. This E-Liquid
							is an extra juicy blend of lemonade, cherry, raspberry, and
							blueberry to help you beat the heat.
						</p>
						<div className='btngroup'>
							<a
								type='button'
								href='https://buffalodistro.com/product-category/e-juice/the-fourth/'
								className='tfwholesale   '>
								Wholesale
							</a>
							<a
								type='button'
								href='https://truevapeusa.com/product-category/ejuice/the-fourth/'
								className=' tfretail  '>
								Retail
							</a>
						</div>
					</div>
				</div>
			</header>
		</>
	);
}

export default TheFourth;
