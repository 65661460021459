import React from "react";

import "./style.css";
import lemon from "../../images/lemon.png";

function lemonLaw() {
	return (
		<>
			<header className='container '>
				<div className='row green brands'>
					<div className='col-xs-6 logos '>
						<img
							src={lemon}
							alt='by-the-pound'
							className='img-fluid '
							height='150px'
							width='500px'
						/>

						<p className='byp-content-header green'>
							Lemon Law E-Liquids offer vapors of bright tart citrus balanced
							out with sweet raspberries or spicey ginger. Let the clouds blow
							over you like condensation running down an icy glass in July.
							Available in Raspberry Lemonade and Ginger Lemonade.
						</p>

						<div className='btngroup'>
							<a
								type='button'
								href='https://buffalodistro.com/product-category/e-juice/lemon-law/'
								className='llwholesale   '>
								Wholesale
							</a>
							<a
								type='button'
								href='https://truevapeusa.com/product-category/ejuice/lemon-law/'
								className=' llretail  '>
								Retail
							</a>
						</div>
					</div>
				</div>
			</header>
		</>
	);
}

export default lemonLaw;
