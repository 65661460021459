import React from "react";
import "./style.css";

function Footer() {
	return (
		<footer id='sticky-footer' className='py-4 '>
			<div className='container text-center'>
				<small>
					All products on this page are owned and manufactured exclusively by
					Vapor Solutions & Labs L.L.C. and distributed by Buffalo Distro.
					888.223.0777
				</small>
			</div>
		</footer>
	);
}

export default Footer;
