import React from "react";

import "./style.css";

import country from "../../images/country.png";
import BBP from "../../images/BBP.png";
import BCBP from "../../images/BCBP.png";
import CCP from "../../images/CCP.png";
import LPP from "../../images/LPP.png";
import SCBP from "../../images/SCBP.png";
import CBP from "../../images/CBP.png";

function countryClouds() {
	return (
		<>
			<header className='container '>
				<div className='row green brands'>
					<div className='col-xs-6 logos '>
						<img
							src={country}
							alt='by-the-pound'
							className='img-fluid '
							height='150px'
							width='500px'
						/>
						<div className='col-xs-6'>
							<p className='country-content-header'>
								These sweet southern home cooking inspired E-Liquids will have
								you happier than ol’ Blue layin’ on the porch chewin’ on a big
								ol’ catfish head. Available in 6 classic flavors: Corn Bread
								Puddin’, Blueberry Corn Bread Puddin’, Strawberry Cornbread
								Puddin’, Banana Bread Puddin’, Chocolate Puddin’ Pie, and Lemon
								Puddin’’ Pie. Keep your eyes out for our high nicotine series.
								Comin soon!
							</p>
						</div>
					</div>
					<div className='col-lg-12 logos'>
						<div className='row'>
							<div className='col-md-4'>
								<div className='thumbnail'>
									<img src={BBP} alt='BBP' className='img-fluid' />

									<div className='btngroup'>
										<a
											type='button'
											href='https://buffalodistro.com/product-category/e-juice/country-clouds/'
											className='wholesalebtn  btn '>
											Wholesale
										</a>
										<a
											type='button'
											href='https://truevapeusa.com/product-category/ejuice/country-clouds/'
											className=' retailbtn btn '>
											Retail
										</a>
									</div>
								</div>
							</div>
							<div className='col-md-4'>
								<div className='thumbnail'>
									<img src={BCBP} alt='Nature' className='img-fluid' />
									<div className='btngroup'>
										<a
											type='button'
											href='https://buffalodistro.com/product-category/e-juice/country-clouds/'
											className='wholesalebtn   '>
											Wholesale
										</a>
										<a
											type='button'
											href='https://truevapeusa.com/product-category/ejuice/country-clouds/'
											className=' retailbtn  '>
											Retail
										</a>
									</div>
								</div>
							</div>
							<div className='col-md-4'>
								<div className='thumbnail'>
									<img src={CCP} alt='Fjords' className='img-fluid' />

									<div className='btngroup'>
										<a
											type='button'
											href='https://buffalodistro.com/product-category/e-juice/country-clouds/'
											className='wholesalebtn   '>
											Wholesale
										</a>
										<a
											type='button'
											href='https://truevapeusa.com/product-category/ejuice/country-clouds/'
											className=' retailbtn  '>
											Retail
										</a>
									</div>
								</div>
							</div>
							<div className='col-md-4'>
								<div className='thumbnail'>
									<img src={LPP} alt='Fjords' className='img-fluid' />

									<div className='btngroup'>
										<a
											type='button'
											href='https://buffalodistro.com/product-category/e-juice/country-clouds/'
											className='wholesalebtn    '>
											Wholesale
										</a>
										<a
											type='button'
											href='https://truevapeusa.com/product-category/ejuice/country-clouds/'
											className=' retailbtn  '>
											Retail
										</a>
									</div>
								</div>
							</div>
							<div className='col-md-4'>
								<div className='thumbnail'>
									<img src={SCBP} alt='Fjords' className='img-fluid' />
									<div className='btngroup'>
										<a
											type='button'
											href='https://buffalodistro.com/product-category/e-juice/country-clouds/'
											className='wholesalebtn   '>
											Wholesale
										</a>
										<a
											type='button'
											href='https://truevapeusa.com/product-category/ejuice/country-clouds/'
											className=' retailbtn  '>
											Retail
										</a>
									</div>
								</div>
							</div>
							<div className='col-md-4'>
								<div className='thumbnail'>
									<img src={CBP} alt='Fjords' className='img-fluid' />

									<div className='btngroup'>
										<a
											type='button'
											href='https://buffalodistro.com/product-category/e-juice/country-clouds/'
											className='wholesalebtn   '>
											Wholesale
										</a>
										<a
											type='button'
											href='https://truevapeusa.com/product-category/ejuice/country-clouds/'
											className=' retailbtn  '>
											Retail
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</header>
		</>
	);
}

export default countryClouds;
