import React from "react";

import "./style.css";

import PMTA from "../../images/PMTA.jpg";

function Landing() {
	return (
		<>
			<header className='container-fluid yellow'>
				<a
					href='http://www.vaporsolutionslabs.com/legal'
					className='btn btn-secondary d-flex justify-content-center text-center learn-more-btn'>
					Learn More Here
				</a>
				<div className='row green brands'>
					<div className='col-xs-6 logos '>
						<img
							src={PMTA}
							alt='Community letter'
							className='img-fluid '
							height='500px'
							width='800px'
						/>
					</div>
				</div>
			</header>
		</>
	);
}

export default Landing;
