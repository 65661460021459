import React from "react";

import "./style.css";

import sqeez from "../../images/sqeez.png";

function Sqeez() {
	return (
		<>
			<header className='container yellow'>
				<div className='row green brands'>
					<div className='col-xs-6 logos '>
						<img
							src={sqeez}
							alt='by-the-pound'
							className='img-fluid '
							height='150px'
							width='500px'
						/>
						<p class='byp-content-header'>
							Sqeez is back! This brand has built up a huge cult following and
							now they can rest easy. We’ve got Berryade, Limeade, and
							Strawberry Lemonade forevermore. Keep an eye out for our new Sqeez
							high nic series featuring new flavors like Tropical, Sunsets, and
							Tropical Fever.
						</p>
						<div className='btngroup'>
							<a
								type='button'
								href='https://buffalodistro.com/product-category/e-juice/sqeez/'
								className='wholesales   '>
								Wholesale
							</a>
							<a
								type='button'
								href='https://truevapeusa.com/product-category/ejuice/sqeez/'
								className=' retails'>
								Retail
							</a>
						</div>
					</div>
				</div>
			</header>
		</>
	);
}

export default Sqeez;
