import React from "react";

import "./style.css";
import CC from "../../images/cchinic.jpg";
import coco from "../../images/cocohinic.jpg";
import sqeezhigh from "../../images/sqeezhinic.jpg";

function Hinic() {
	return (
		<>
			<header className='container yellow'>
				<div className='row green brands'>
					<div className='col-xs-6 logos '>
						<img
							src={CC}
							alt='by-the-pound'
							className='img-fluid '
							height='150px'
							width='500px'
						/>
						<img
							src={coco}
							alt='by-the-pound'
							className='img-fluid '
							height='150px'
							width='500px'
						/>
						<img
							src={sqeezhigh}
							alt='by-the-pound'
							className='img-fluid '
							height='150px'
							width='500px'
						/>
					</div>
				</div>
			</header>
		</>
	);
}

export default Hinic;
