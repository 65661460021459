import React from "react";
import { Link, useLocation } from "react-router-dom";

import "./style.css";
// Images
import country from "../../images/country.png";
import lemon from "../../images/lemon.png";
import pound from "../../images/pound.png";
import sqeez from "../../images/sqeez.png";
import grind from "../../images/grind.png";
import guard from "../../images/guard.png";
import fourth from "../../images/4th.png";
import etho from "../../images/etho.png";
//
import Button from "../../components/button/index";

function Manufacturing() {
	return (
		<>
			<div className='container manu'>
				<div className='row'>
					<div className='about my-4 h1-responsive'>
						<p>Manufacturing</p>
					</div>
				</div>
				<div className='row'>
					<div className='col-lg-12'>
						<div className='about-content'>
							The Sweetest, Fluffiest, Safest Clouds on the Market.
						</div>
						<p className='content'>
							From our home in Ashland Virginia, Vapor Solutions Labs has dialed
							in and perfected the art of producing top quality, original
							vapors. Our E-liquid manufacturing laboratory upholds the
							strictest protocols for health and safety. Every patch of our lab
							technician’s fleecy frames is covered. Lab coats, hair and beard
							nets, and surgical gloves are only a few of the general safety
							precautions we insist on our chemists using when creating our
							products. Every batch of juice is tested for purity and potency to
							ensure we manufacture top-notch products that not only showcase
							originality but also tenaciously apply health and safety codes
							developed to protect our clientele.
						</p>
						<div className='about-content'>We Care About Every Detail</div>
						<p className='content'>
							Our chemists have wildly diverse backgrounds, but each brings to
							the table an artistic fervor for concepts and flavors. They work
							tirelessly to bring fresh and cool ideas to the table and your pod
							system. The smells wafting from Vapor Solutions are rich,
							stimulating, and sweet. At Vapor Solutions, we take what we do
							very seriously. We are serious about integrity. We are serious
							about fun. And we hope this comes through our products to you, our
							cherished vapor. First, our mixologists and flavor makers bring
							together a fresh and delicious blend. Our production team tests
							and packages that sweet juice with hard plastic and pretty colors.
							Those bottles are wrapped, packed, and shipped to your home or
							your store, and your world gets a little brighter.
						</p>
					</div>
				</div>
				{/* <div className='accordion'>
					<div className='accordion' id='accordionExample'>
						<div className='card'>
							<div className='card-header' id='headingOne'>
								<h2 className='mb-0'>
									<div
										className='btn btn-link btn-block text-left collapsed button-style about-content '
										type='button'
										data-toggle='collapse'
										data-target='#collapseOne'
										aria-expanded='true'
										aria-controls='collapseOne'>
										Manufacturing Capabilities
									</div>
								</h2>
							</div>

							<div
								id='collapseOne'
								className='collapse show'
								aria-labelledby='headingOne'
								data-parent='#accordionExample'>
								<div className='card-body content'>
									<ul>
										<li>40,000 Sq ft Facility</li>

										<li>
											Full automated line: Machine filling, Capping, Batch
											coding, Tamper evident neck band or heat induction
										</li>

										<li>
											Capable of Manufacturing More than 30,000 Bottles a Day
										</li>

										<li>State of the Art Manufacturing Equipment</li>

										<li>Short Lead Times</li>
									</ul>
								</div>
							</div>
						</div>
						<div className='card'>
							<div className='card-header' id='headingTwo'>
								<h2 className='mb-0'>
									<div
										className='btn btn-link btn-block text-left collapsed button-style about-content'
										type='button'
										data-toggle='collapse'
										data-target='#collapseTwo'
										aria-expanded='false'
										aria-controls='collapseTwo'>
										Experienced Mixologists And Flavorist
									</div>
								</h2>
							</div>
							<div
								id='collapseTwo'
								className='collapse'
								aria-labelledby='headingTwo'
								data-parent='#accordionExample'>
								<div className='card-body content'>
									<ul>
										<li>Culinary Trained Flavorists</li>
										<li>
											Testing for Potentially Harmful Ingredients (diacetyl,
											acetyl propionyl, etc)
										</li>
										<li>
											Innovative Flavor Development and Enhancement Processes -
											Excluding USA due to FDA Regulation unless approved
											otherwise
										</li>
										<li>Advanced techniques for Blending and Steeping</li>
									</ul>
								</div>
							</div>
						</div>
						<div className='card'>
							<div className='card-header' id='headingThree'>
								<h2 className='mb-0'>
									<div
										className='btn btn-link btn-block text-left collapsed button-style about-content'
										type='button'
										data-toggle='collapse'
										data-target='#collapseThree'
										aria-expanded='false'
										aria-controls='collapseThree'>
										Premium Private Label E-juice Services
									</div>
								</h2>
							</div>
							<div
								id='collapseThree'
								className='collapse'
								aria-labelledby='headingThree'
								data-parent='#accordionExample'>
								<div className='card-body content'>
									<li>Culinary Trained Flavorists</li>
									<li>
										Quick Product Development from Conception to Shelf for the
										World Market - excluding USA due to FDA regulations unless
										approved otherwise
									</li>
									<li>Premium White Label Flavoring and Branding</li>
									<li>Bottling and Co-packing</li>
									<li>Full Confidentiality</li>
									<li>
										Exceptional Customer Service and Communication in Every Step
										of the Process
									</li>
									<li>TPD / PMTA Compliance Consultation and Development</li>
									<Link to='/contact-us'>
										<Button renderAs='button'>
											<span>Contact us for more Information</span>
										</Button>
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div> */}
				<div className='row'>
					<div className='headline'>Manufactured By Vapor Solutions Labs</div>
					<div className='container-fluid '>
						<div className='row d-flex justify-content-center '>
							<div className='col-lg-12 logos'>
								<img
									src={country}
									alt='country-clouds'
									className='img-fluid '
									height='50px'
									width='250px'
								/>
								<img
									src={grind}
									alt='the-grind'
									className='img-fluid  '
									height='50px'
									width='250px'
								/>
								<img
									src={sqeez}
									alt='sqeez'
									className='img-fluid  '
									height='50px'
									width='250px'
								/>
								<img
									src={etho}
									alt='sqeez'
									className='img-fluid  '
									height='50px'
									width='250px'
								/>

								<img
									src={lemon}
									alt='lemon-law'
									className='img-fluid '
									height='50px'
									width='250px'
								/>
								<img
									src={pound}
									alt='by-the-pound'
									className='img-fluid '
									height='50px'
									width='250px'
								/>
								<img
									src={fourth}
									alt='the-fouth'
									className='img-fluid  '
									height='50px'
									width='250px'
								/>
								<img
									src={guard}
									alt='by-the-pound'
									className='img-fluid  '
									height='50px'
									width='250px'
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Manufacturing;
