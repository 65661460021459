import React from "react";

import "./style.css";

import bottles from "../../images/bottles.png";
import country from "../../images/country.png";
import lemon from "../../images/lemon.png";
import pound from "../../images/pound.png";
import sqeez from "../../images/sqeez.png";
import grind from "../../images/grind.png";
import guard from "../../images/guard.png";
import fourth from "../../images/4th.png";
import etho from "../../images/etho.png";
import letter from "../../images/letter.jpg";
// import logo from "../../images/logo.png";

function Home() {
	return (
		<>
			<header className='container-fluid yellow'>
				<div className='row green brands'>
					<div className='col-xs-6 logos '>
						<img
							src={bottles}
							alt='factory pic'
							className='img-fluid '
							height='350px'
							width='700px'
						/>
					</div>

					<div className='col-xs-6 logos '>
						<img
							src={country}
							alt='country-clouds'
							className='img-fluid '
							height='50px'
							width='350px'
						/>
						<img
							src={grind}
							alt='the-grind'
							className='img-fluid  '
							height='50px'
							width='250px'
						/>
						<img
							src={sqeez}
							alt='sqeez'
							className='img-fluid  '
							height='50px'
							width='250px'
						/>
						<img
							src={fourth}
							alt='the-fouth'
							className='img-fluid  '
							height='50px'
							width='250px'
						/>

						<img
							src={lemon}
							alt='lemon-law'
							className='img-fluid '
							height='50px'
							width='250px'
						/>
						<img
							src={pound}
							alt='by-the-pound'
							className='img-fluid '
							height='50px'
							width='250px'
						/>
						<img
							src={guard}
							alt='by-the-pound'
							className='img-fluid  '
							height='50px'
							width='250px'
						/>
						<img
							src={etho}
							alt='sqeez'
							className='img-fluid  '
							height='50px'
							width='250px'
						/>
					</div>
					<br></br>
					<br></br>
				</div>
			</header>
		</>
	);
}

export default Home;
