import React from "react";

import "./faq.css";

function Faq() {
	return (
		<>
			<div className='container faq'>
				<div className='row'>
					<p className='about h1-responsive  my-4'>F.A.Q.</p>
				</div>

				<p className=' about-content'>Q: WHAT IS AN E-CIGARETTE?</p>
				<p className='content'>
					A: Electronic cigarettes, also known as vape devices, rigs,
					pod-systems, vaporizers, mods, e-hookahs, juice boxes, and mouth
					fedoras, are an alternative to traditional cigarettes in which the
					tobacco is lit with a flame. These devices rely on battery-operated
					power to fuel the heat source, creating a water-based vapor instead of
					smoke. The heat source in question is called an “atomizer.” The
					atomizer generates heat which converts the E-Liquid you’ve selected
					for your cartridge into inhalable mist.
				</p>
				<p className='about-content'>Q: WHAT IS A VAPE DEVICE?</p>
				<p className='content content'>
					A: A vape device has three major components, all working towards the
					same objective of vaporizing your E-Liquid. First there is a
					rechargeable battery . This will screw onto the second component,
					which is the tank which will hold the E-Liquid and the atomizer. These
					come in an array of sizes and designs which can be modified to the
					vaper’s preference. Mods are a popular term to refer to one's vaping
					device once it has been customized. The power source is usually turned
					on and off by a button on the side of the device, however, some mods
					can be made that cause the vaping device to engage instantaneously as
					the vaper pulls from it. The atomizer is engaged by the battery and
					the E-Liquid is vaporized. There are a plethora of awesome mod options
					on the market today to make building a vaping device an exciting and
					customizable experience.
				</p>
				<p className='about-content'>Q: WHAT IS E-LIQUID?</p>
				<p className='content'>
					A: There are four general components in every bottle of E-Liquid. They
					are propylene glycol (PG), vegetable glycerin (VG), nicotine, and
					food-grade flavorings. Not every bottle of E-Liquid will have the same
					ratios of all these components. Some E-Liquids have 0Mg of nicotine.
					Depending on the smoking preference of the vapor, they may look for an
					E-Liquid that has a higher concentration of PG versus VG. VG is thick
					and tasteless. VG will produce thicker clouds. PG is a thinner liquid
					that has a slight sweetness. PG has a throat hit more akin to smoking
					a cigarette. There are some products on the market that produce
					E-Liquid with 100% PG or VG, but generally 70/30
				</p>
				<p className='about-content'>Q: WHAT IS A BEST E JUICE? </p>
				<p className='content'>
					A: The best juice is the safest juice. The safest juice is
					manufactured in a facility like Vapor Solutions Labs, where every
					health standard is vehemently abided and transparency of our processes
					are attainable by anyone intending to use our products. .
				</p>
				<p className='about-content'>WHAT IS VAPOR SOLUTIONS LABS E-JUICE? </p>
				<p className='content'>
					A: Vapor Solutions Labs is based out of Ashland Virginia. We
					manufacture all of our E-Liquid brands for U.S. and international
					distribution.
				</p>
				<p className='about-content'>Q: WHAT DOES “PG/VG” STAND FOR? </p>
				<p className='content'>
					A: PG is Propylene Glycol, a thin liquid that has a slight sweet taste
					to it. VG is Vegetable Glycerin, a thicker and tasteless liquid. Both
					liquids are often found in many food products and are a base for all
					e-liquids.
				</p>
				<p className='about-content'>Q: WHAT MIX OF PG/VG SHOULD I GET? </p>
				<p className='content'>
					A: PG/VG mix is very subjective depending on the person. A higher PG
					produces less vapor (fog) and is thinner. A higher VG ratio produces
					more vapor (fog) and is thicker. In addition, higher PG ratios tend to
					have more potent flavors. A higher VG in vape juice results in larger
					clouds, making it ideal for use in vape cloud competitions.
				</p>
				<p className='about-content'>
					Q: WHAT IS THE DIFFERENCE BETWEEN NICOTINE AND TOBACCO?
				</p>
				<p className='content'>
					A: Nicotine and tobacco are not the same thing. Tobacco in traditional
					cigarettes consists of dried leaves that contain nicotine naturally.
					Nicotine, as a liquid, can be extracted from many different sources
					and then added into a PG/VG base along with flavorings.
				</p>
				<p className='about-content'>Q: ARE E-CIGARETTES SAFE? </p>
				<p className='content'>
					A: At Vapor Solutions Labs, we work tirelessly to uphold the highest
					possible standards of health and safety in making our E-Liquids.
					Customers are encouraged to be curious and critical of the practice of
					vaping.
				</p>
				<p className='about-content'>
					Q: ARE E-CIGARETTES REGULATED BY THE FDA?{" "}
				</p>
				<p className='content'>
					A: The FDA began the regulations of E-Cigarettes and E-Liquids as
					nicotine products under the Food, Drug, and Cosmetics Act in May of
					2016.
				</p>
			</div>
		</>
	);
}

export default Faq;
