import React from "react";

import "./style.css";

// Pound Logo
import pound from "../../images/pound.png";
// Flavors
import milk from "../../images/milk.png";
import blueberry from "../../images/blueberry.png";
import coco from "../../images/coco.png";
import strawberry from "../../images/strawberry.png";

function bythePound() {
	return (
		<>
			<header className='container '>
				<div className='row green brands'>
					<div className='col-xs-6 logos '>
						<img
							src={pound}
							alt='by-the-pound'
							className='img-fluid '
							height='150px'
							width='500px'
						/>
						<p class='byp-content-header'>
							This luxury dessert line features dense, rich flavors of pound
							cake dripping with cream and fruit.
						</p>
					</div>
					<div className='col-lg-12 logos'>
						<div class='thumbnail'>
							<img src={milk} alt='Lights' className='img-fluid ' />
							<div class='byp-content'>
								<div className='btngroup'>
									<a
										type='button'
										href='https://buffalodistro.com/product-category/e-juice/by-the-pound/'
										className='btpwholesale   '>
										Wholesale
									</a>
									<a
										type='button'
										href='https://truevapeusa.com/product-category/ejuice/by-the-pound/'
										className=' btpretail  '>
										Retail
									</a>
								</div>
							</div>
						</div>
						<div class='thumbnail'>
							<img src={coco} alt='coco' className='img-fluid ' />
							<div class='byp-content'>
								<div className='btngroup'>
									<a
										type='button'
										href='https://buffalodistro.com/product-category/e-juice/by-the-pound/'
										className='btpwholesale   '>
										Wholesale
									</a>
									<a
										type='button'
										href='https://truevapeusa.com/product-category/ejuice/by-the-pound/'
										className=' btpretail  '>
										Retail
									</a>
								</div>
							</div>
						</div>

						<div class='thumbnail'>
							<img src={blueberry} alt='blueberry' className='img-fluid ' />
							<div class='byp-content'>
								<div className='btngroup'>
									<a
										type='button'
										href='https://buffalodistro.com/product-category/e-juice/by-the-pound/'
										className='btpwholesale   '>
										Wholesale
									</a>
									<a
										type='button'
										href='https://truevapeusa.com/product-category/ejuice/by-the-pound/'
										className=' btpretail  '>
										Retail
									</a>
								</div>
							</div>
						</div>

						<div class='thumbnail'>
							<img src={strawberry} alt='strawberry' className='img-fluid ' />
							<div class='byp-content'>
								<div className='btngroup'>
									<a
										type='button'
										href='https://buffalodistro.com/product-category/e-juice/by-the-pound/'
										className='btpwholesale   '>
										Wholesale
									</a>
									<a
										type='button'
										href='https://truevapeusa.com/product-category/ejuice/by-the-pound/'
										className=' btpretail  '>
										Retail
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</header>
		</>
	);
}

export default bythePound;
