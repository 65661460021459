import React, { Component } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
// Components
import Footer from "./components/footer/index";
// Pages
import home from "./pages/home/index";

import manufacturing from "./pages/manufacturing/index";
import AboutUs from "./pages/aboutus/index";
import Landing from "./pages/landing/index";
import legal from "./pages/legal/index";
// Product Pages
import TheGrind from "./pages/thegrind/index";
import Sqeez from "./pages/sqeez/index";
import ByThePound from "./pages/bythepound/index";
import LiquidGuard from "./pages/liquidguard/index";
import LemonLaw from "./pages/lemonlaw/index";
import CountryClouds from "./pages/countryclouds/index";
import TheFourth from "./pages/thefourth";
import Hinic from "./pages/hi-nic-series";
// Info Pages
import Faq from "./pages/faq/index";
import ContactUs from "./pages/contactus/index";
// Logo
import logo from "../src/images/logo.png";

import "./App.css";

class App extends Component {
	constructor(props) {
		super(props);
		this.state = { color: "#000000" };
	}

	changeColor = (color) => {
		this.setState({ color });
	};

	render() {
		return (
			<>
				<div style={{ background: this.state.color }} id='main'>
					<Router>
						<div>
							<div className='ContentDiv'>
								<nav className='navbar navbar-expand-lg '>
									<div className='container'>
										<Link to='/home'>
											<a>
												<img
													src={logo}
													alt='Logo'
													className='img-fluid'
													height='50px'
													width='250px'
												/>
											</a>
										</Link>

										<button
											className='navbar-toggler '
											data-toggle='collapse'
											data-target='#navbarResponsive'
											aria-controls='navbarResponsive'
											aria-expanded='false'
											aria-label='Toggle navigation'>
											<span className='navbar-toggler-icon spanicon'>
												<svg
													width='1em'
													height='1em'
													viewBox='0 0 16 16'
													class='bi bi-arrow-down-circle'
													fill='currentColor'
													xmlns='http://www.w3.org/2000/svg'>
													<path
														fill-rule='evenodd'
														d='M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z'
													/>
													<path
														fill-rule='evenodd'
														d='M4.646 7.646a.5.5 0 0 1 .708 0L8 10.293l2.646-2.647a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 0 1 0-.708z'
													/>
													<path
														fill-rule='evenodd'
														d='M8 4.5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5z'
													/>
												</svg>
											</span>
										</button>
										<div
											className='collapse navbar-collapse justify-content-end'
											id='navbarResponsive'>
											<ul className='navbar-nav '>
												<li className='nav-item highlight'>
													<Link
														to='/home'
														onClick={() => this.changeColor("#000000")}
														className='nav-link active
																		nav-link'>
														Home
													</Link>
												</li>
												<li className='nav-item highlight'>
													<Link
														to='/about-us'
														onClick={() => this.changeColor("#000000")}
														className='nav-link active
																		nav-link'>
														About Us
													</Link>
												</li>
												<li className='nav-item dropdown'>
													<div
														className='nav-link dropdown-toggle'
														href='#'
														id='navbarDropdown'
														role='button'
														data-toggle='dropdown'
														aria-haspopup='true'
														aria-expanded='false'>
														Products
													</div>
													<div
														className='dropdown-menu'
														aria-labelledby='navbarDropdown'>
														<div className='dropdown-item' href='#'>
															<Link
																to='/country-clouds'
																onClick={() => this.changeColor("#000000")}
																className='nav-link active
																		nav-link'>
																Country Clouds
															</Link>
														</div>

														<div className='dropdown-item' href='#'>
															<Link
																to='/the-grind'
																onClick={() => this.changeColor("	#000000")}
																className='nav-link active
																		nav-link'>
																The Grind
															</Link>
														</div>
														<div className='dropdown-item' href='#'>
															<Link
																to='/by-the-pound'
																onClick={() => this.changeColor("#000000")}
																className='nav-link active
																		nav-link'>
																By The Pound
															</Link>
														</div>
														<div className='dropdown-item' href='#'>
															<Link
																to='/sqeez'
																onClick={() => this.changeColor("#000000")}
																className='nav-link active
																		nav-link'>
																Sqeez
															</Link>
														</div>

														<div className='dropdown-item' href='#'>
															<Link
																to='/liquid-guard'
																onClick={() => this.changeColor("	#000000")}
																className='nav-link active
																		nav-link'>
																Liquid guard
															</Link>
														</div>
														<div className='dropdown-item' href='#'>
															<Link
																to='/lemon-law'
																onClick={() => this.changeColor("#000000")}
																className='nav-link active
																		nav-link'>
																Lemon Law
															</Link>
														</div>
														<div className='dropdown-item' href='#'>
															<Link
																to='/the-fourth'
																onClick={() => this.changeColor("#000000")}
																className='nav-link active
																		nav-link'>
																The Fourth
															</Link>
														</div>
														<div className='dropdown-item' href='#'>
															<Link
																to='/hi-nic'
																onClick={() => this.changeColor("#000000")}
																className='nav-link active
																		nav-link'>
																Hi-Nic Series
															</Link>
														</div>
													</div>
												</li>
												<li className='nav-item dropdown'>
													<div
														className='nav-link dropdown-toggle'
														href='#'
														id='navbarDropdown'
														role='button'
														data-toggle='dropdown'
														aria-haspopup='true'
														aria-expanded='false'>
														Info
													</div>
													<div
														className='dropdown-menu'
														aria-labelledby='navbarDropdown'>
														<div className='dropdown-item'>
															<Link
																to='/faq'
																onClick={() => this.changeColor("#000000")}
																className='nav-link active
																		nav-link'>
																FAQ
															</Link>

															<Link
																to='/contact-us'
																onClick={() => this.changeColor("#000000")}
																className='nav-link active
																		nav-link'>
																Contact Us
															</Link>
															<Link
																to='/legal'
																onClick={() => this.changeColor("#000000")}
																className='nav-link active
																		nav-link'>
																Legal
															</Link>
														</div>
													</div>
												</li>
												<li className='nav-item'>
													<Link
														to='/manufacturing'
														onClick={() => this.changeColor("#000000")}
														className='nav-link active
																		nav-link'>
														Manufacturing
													</Link>
												</li>
												<li className='nav-item active'>
													<a
														className='nav-link'
														href='https://www.buffalodistro.com'>
														Wholesale <span className='sr-only'>(current)</span>
													</a>
												</li>
												<li className='nav-item active'>
													<a
														className='nav-link'
														href='https://www.truevapeusa.com'>
														Retail <span className='sr-only'>(current)</span>
													</a>
												</li>
											</ul>
										</div>
									</div>
								</nav>
								<Route exact path='/' component={Landing} />
								<Route exact path='/home' component={home} />
								<Route exact path='/legal' component={legal} />
								<Route exact path='/about-us' component={AboutUs} />
								<Route exact path='/manufacturing' component={manufacturing} />
								<Route exact path='/the-grind' component={TheGrind} />
								<Route exact path='/sqeez' component={Sqeez} />
								<Route exact path='/by-the-pound' component={ByThePound} />
								<Route exact path='/liquid-guard' component={LiquidGuard} />
								<Route exact path='/lemon-law' component={LemonLaw} />
								<Route exact path='/country-clouds' component={CountryClouds} />
								<Route exact path='/faq' component={Faq} />
								<Route exact path='/contact-us' component={ContactUs} />
								<Route exact path='/the-fourth' component={TheFourth} />
								<Route exact path='/hi-nic' component={Hinic} />
							</div>
							<Footer />
						</div>
					</Router>
				</div>
			</>
		);
	}
}

export default App;
